/* istanbul ignore file */
import { Dispatch, Fragment, SetStateAction, useCallback, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useMutation } from 'react-query';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { parse } from 'date-fns';

import { Dropoff } from '@assets/svg/icons';
import { useFacility } from '@common/hooks';
import {
  BDDrive,
  BookingDetails,
  BookingRouteReject,
  BookingTripType,
  BookingUpdate,
  DriveStatus,
  DriveUpdate,
  PlAcceptDrive,
  PlDrive,
  PlDriver,
  PlResource,
  PlVehicle,
  TransferType,
} from '@common/interfaces';
import { DateFormat as DF } from '@common/types';
import { formatDateString, getErrors } from '@common/utils';
import { Button, Form, Loader } from '@components';
import { useRepository } from '@context';
import { AcceptDriveForm, BookingInfo, EditDriveFieldset } from './components';
import {
  DriveEditFormSchema,
  DriveEditFormType,
  DriveFormType,
} from '../../schema/DriveForm.schema';
import { checkConflictDriveOnEdit, MPSerializer } from '../../utils';
import './BookingPanel.styles.scss';

interface BookingPanelProps {
  acceptDrives?: PlAcceptDrive[];
  booking: BookingDetails;
  drivesData?: PlDrive[];
  drivers: PlDriver[];
  isAccept?: boolean;
  isEdit?: boolean;
  resource?: [PlResource | null, Dispatch<SetStateAction<PlResource | null>>];
  setAcceptDrives?: Dispatch<SetStateAction<PlAcceptDrive[]>>;
  setDate?: Dispatch<SetStateAction<Date | null>>;
  vehicles: PlVehicle[];
  onDriveCancel?: () => void;
}

const BookingPanel = ({
  acceptDrives,
  booking,
  drivesData,
  drivers,
  isAccept,
  isEdit,
  resource,
  setAcceptDrives,
  setDate,
  vehicles,
  onDriveCancel,
}: BookingPanelProps) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const { bookingRepository, plannerRepo } = useRepository();
  const { facility, facilityId } = useFacility();

  const [isDriveEditFormTouched, setDriveEditFormTouched] = useState(false);
  const [isVerified, setVerified] = useState(false);

  const { mutateAsync: updateDrives, isLoading: isUpdateProcess } = useMutation(
    (drives: DriveUpdate[]) => plannerRepo.updateDrive(facilityId, booking?.id!, drives),
    {
      onSuccess: () => {
        if (isEdit)
          setTimeout(() => navigate(`${pathname.split('/').slice(0, -2).join('/')}`), 500);
        toast.success(t('mobility.msgUpdateDrive'));
      },
      onError: (e: any) => {
        if (e.response && Object.values(e.response?.data.errors).length) {
          toast.error(getErrors(e.response.data.errors) || t('common.errorMsgDefault'));
        }
      },
    },
  );

  const { mutateAsync: cancelDrive, isLoading: isCancelProcess } = useMutation(
    (driveId: string) => plannerRepo.cancelDrive(facilityId, driveId),
    {
      onSuccess: () => {
        onDriveCancel?.();
        toast.success(t('mobility.msgDriveCancel'));
      },
      onError: (e: any) => {
        if (e.response && Object.values(e.response?.data.errors).length) {
          toast.error(getErrors(e.response.data.errors) || t('common.errorMsgDefault'));
        }
      },
    },
  );

  const { mutateAsync: rejectBooking, isLoading: isRejectProcess } = useMutation(
    (data: BookingUpdate) =>
      bookingRepository.rejectBooking(facilityId, facility?.agencyId!, booking?.id!, data),
    {
      onSuccess: () => {
        toast.warning(t('booking.msgBookingRejected'));
        navigate(`${pathname.split('/').slice(0, -2).join('/')}`);
      },
      onError: (e: any) => {
        if (e.response) toast.error(getErrors(e?.response?.data));
      },
    },
  );

  const { mutateAsync: rejectRoute, isLoading: isRejectRouteProcess } = useMutation(
    (payload: BookingRouteReject) => bookingRepository.rejectRoute(facilityId, payload),
    {
      onSuccess: () => {
        toast.warning(t('booking.msgRouteRejected'));
      },
      onError: (e: any) => {
        if (e.response) toast.error(getErrors(e?.response?.data));
      },
    },
  );

  const { drives, routes, transferType, typeOfTrip } = booking;
  const isOneWay = typeOfTrip === BookingTripType.OneWay;

  const handleEditDriveFormSubmit = useCallback(
    async (formState: DriveEditFormType) => {
      if (!isVerified && !isOneWay) {
        const isConflict = checkConflictDriveOnEdit({
          drives: formState?.drives?.filter(
            (_, i: number) => drives[i].state === DriveStatus.NotStarted,
          ),
        });

        if (isConflict) return toast.error(t('planner.msgConflictingDrivesEdit'));

        setVerified(true);
        return toast.success(t('planner.msgDriveVerified'));
      }

      const mappedDrives = formState?.drives
        ?.map((d: DriveFormType, i: number) => MPSerializer.mapDriveEditToUpdate(d, drives[i]))
        .filter((drive: DriveUpdate) => drive.state === DriveStatus.NotStarted);

      updateDrives(mappedDrives);
      setDriveEditFormTouched(false);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isVerified],
  );

  const schema = useMemo(() => DriveEditFormSchema(), []);

  const initFormData = useMemo(
    () => ({
      drives: drives.map((drive) => ({
        driver: drive?.driverId,
        dropoffDate: parse(drive?.dropoffDate, DF.ApiDate, new Date()),
        dropoffTime: parse(drive?.dropoffTime, DF.ApiTime, new Date()),
        pickupDate: parse(drive?.pickupDate, DF.ApiDate, new Date()),
        pickupTime: parse(drive?.pickupTime, DF.ApiTime, new Date()),
        vehicle: drive?.vehicleId,
        ...(drive?.commentToDriver && { commentDriver: drive.commentToDriver }),
        ...(drive?.commentToPax && { commentPax: drive.commentToPax }),
      })),
    }),
    [drives],
  );

  const currentAcceptDriveIdx = useMemo(
    () => acceptDrives?.findIndex((drive: PlAcceptDrive) => !drive.accepted && !drive.rejected),
    [acceptDrives],
  );
  const isLoading = isCancelProcess || isRejectProcess || isRejectRouteProcess || isUpdateProcess;

  return (
    <Loader spinning={isLoading}>
      <section className="bp theme-dark">
        <BookingInfo booking={booking} />

        <div className="bp-leg" data-testid="planner-booking-panel-form-accept">
          {isAccept &&
            acceptDrives?.map((data: PlAcceptDrive, idx: number) => {
              if (currentAcceptDriveIdx! < idx) return;
              const route = routes[idx];

              return (
                <Fragment key={data.routeNumber}>
                  <div className="bp-leg-title">
                    <div>
                      <h3>
                        {t('bookingDetails.pickupAt')} <span>{route.pickupTime}</span>&nbsp;
                        {route.pickupTown}, {route.pickupLocation}
                      </h3>
                      {formatDateString(route.pickupDate, DF.ApiDate, DF.BookingInfoDate)}
                    </div>
                    <Dropoff />
                    <div>
                      <h3>
                        {t('bookingDetails.dropoffAt')}&nbsp;
                        {transferType === TransferType.Shuttle && <span>{route.dropoffTime}</span>}
                        &nbsp;
                        {route.dropoffTown}, {route.dropoffLocation}
                      </h3>
                      {formatDateString(route.dropoffDate, DF.ApiDate, DF.BookingInfoDate)}
                    </div>
                  </div>

                  <AcceptDriveForm
                    acceptDrives={acceptDrives}
                    agencyId={facility?.agencyId}
                    drives={drivesData!}
                    driveIdx={idx}
                    drivers={drivers}
                    rejectBooking={rejectBooking}
                    rejectRoute={rejectRoute}
                    resource={resource}
                    setAcceptDrives={setAcceptDrives!}
                    setDate={setDate!}
                    vehicles={vehicles}
                  />
                </Fragment>
              );
            })}

          {isEdit && (
            <Form
              className="bp-leg-form bp-leg-form-edit-drive"
              defaultValues={initFormData}
              schema={schema}
              theme="dark"
              onSubmit={handleEditDriveFormSubmit}
            >
              {({ setValue, watch }) => (
                <>
                  {!!(drivers.length && vehicles.length) &&
                    drives?.map((drive: BDDrive) => (
                      <EditDriveFieldset
                        drive={drive}
                        drivers={drivers}
                        key={drive.id}
                        vehicles={vehicles}
                        onCancel={cancelDrive}
                        onChange={() => {
                          setDriveEditFormTouched(true);
                          setVerified(false);
                        }}
                        setValue={setValue}
                        watch={watch}
                      />
                    ))}

                  <div className="bp-leg-form-footer">
                    {(isVerified || isOneWay) && (
                      <Button
                        className="btn btn-confirm"
                        text={t('common.btnConfirm')}
                        type="submit"
                        variant="submit"
                      />
                    )}

                    {!isOneWay && !isVerified && !!vehicles.length && (
                      <Button
                        className="btn btn-verify"
                        disabled={!isDriveEditFormTouched}
                        text={t('common.btnVerify')}
                        type="submit"
                        variant="warning"
                      />
                    )}
                  </div>
                </>
              )}
            </Form>
          )}
        </div>
      </section>
    </Loader>
  );
};

export default BookingPanel;
