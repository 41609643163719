import { useQuery } from 'react-query';
import { useRepository } from '@context';
import { StatusCode } from '@common/types';
import { TRANSLATIONS_EN } from '@common/translations/en';
import i18n from '@common/translations/i18n';

const languages = ['en', 'fr'];

const useTranslations = () => {
  const { accountRepository } = useRepository();

  const fetchTranslations = (lang: string) =>
    accountRepository
      .getTranslations(lang)
      .then(({ status, data }) => (status === StatusCode.SuccessOK ? data : TRANSLATIONS_EN))
      .catch(() => TRANSLATIONS_EN);

  const { isLoading } = useQuery(
    'get-translations',
    async () => {
      const data = await Promise.all(
        languages.map(async (lang) => [lang, await fetchTranslations(lang)]),
      );
      return Object.fromEntries(data);
    },
    {
      onSuccess: (data) => {
        Object.entries(data).forEach(([lang, obj]) => {
          i18n.addResourceBundle(lang, 'translation', obj);
        });
      },
    },
  );

  return { isLoading };
};

export default useTranslations;
